/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { SlideDown } from "react-slidedown"
import FitEducator from "highline/components/pdp/fit_educator"
import classNames from "classnames"
import { ChevronIcons, InfoCircleIcon } from "highline/components/icons"
import Tooltip from "highline/components/tooltip/tooltip"
import ToggleItem from "highline/components/toggle_item"
import styles from "highline/styles/components/toggle_group.module.css"

class ToggleGroup extends React.PureComponent {
  static propTypes = {
    canDeselect: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    isFinalSale: PropTypes.bool,
    isCollapsed: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    isMobile: PropTypes.bool,
    layout: PropTypes.oneOf([
      "default",
      "swatch",
      "text",
      "payments",
      "vertical",
      "smallCircleInline",
      "checkbox",
    ]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onOptionToggle: PropTypes.func,
    showError: PropTypes.bool,
    showFitImages: PropTypes.bool,
    styles: PropTypes.object,
    title: PropTypes.node,
    titleSupplement: PropTypes.node,
    tooltipText: PropTypes.string,
    type: PropTypes.oneOf(["radio", "checkbox"]).isRequired,
    value: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.string]),
    onCollapseClick: PropTypes.func,
    showTitle: PropTypes.bool,
  }

  static defaultProps = {
    canDeselect: false,
    isFinalSale: false,
    isCollapsed: false,
    isCollapsible: false,
    isMobile: false,
    layout: "default",
    onChange: () => {},
    onOptionToggle: () => {},
    showError: false,
    showTitle: true,
    value: "",
  }

  state = {
    showFitEducator: true,
    showTooltip: false,
  }

  handleTooltipOnBlur = (e) => {
    if (this.state.showTooltip) {
      e.stopPropagation()
      this.handleTooltipToggle()
    }
  }

  handleChange = (_, value) => {
    const alreadySelected = value === this.props.value
    if (alreadySelected) {
      this.props.canDeselect && this.props.onChange(this.props.name, null)
      this.handleFitChange(false)
    } else {
      this.props.onChange(this.props.name, value)
      this.handleFitChange(true)
    }
  }

  handleToggleClick = () => {
    const { name, onOptionToggle, isCollapsible } = this.props
    if (isCollapsible) {
      onOptionToggle(name)
    }
  }

  handleFitChange = (toggle) => {
    const { name } = this.props
    const FitOrLength = name.includes("Fit") || name.includes("Length")

    FitOrLength &&
      this.setState({
        showFitEducator: toggle,
      })
  }

  getChildProps = (child) => {
    const checked =
      this.props.type === "radio"
        ? this.props.value === child.props.value
        : this.props.value.includes(child.props.value)

    return {
      checked,
      layout: this.props.layout,
      name: this.props.name,
      onChange: this.handleChange,
      type: this.props.type,
    }
  }

  handleTooltipToggle = () => {
    this.setState({ showTooltip: !this.state.showTooltip })
  }

  handleTooltipKeyDown = (evt) => {
    if (evt.keyCode == 27) {
      if (this.state.showTooltip) {
        evt.stopPropagation()
        this.handleTooltipToggle()
      }
    }
  }

  render() {
    const {
      className,
      isCollapsed,
      isCollapsible,
      isFinalSale,
      isMobile,
      layout,
      name,
      onCollapseClick,
      showError,
      showFitImages,
      showTitle,
      title,
      titleSupplement,
      tooltipText,
    } = this.props
    const { showFitEducator, showTooltip } = this.state

    const children = React.Children.toArray(this.props.children).map((child) => {
      return React.cloneElement(child, this.getChildProps(child))
    })

    const childrenToggleItemLength = children.filter((item) => item.type === ToggleItem).length

    const showPantLengthEducator = name === "pantLength" && childrenToggleItemLength === 1

    const toggleList = (
      <div className={showFitImages ? styles.toggleFitList : styles.toggleList}>
        {children}
        {showFitEducator && (name.includes("Fit") || showPantLengthEducator) && (
          <FitEducator fitType={name} handleFitChange={this.handleFitChange} isMobile={isMobile} />
        )}
      </div>
    )
    const showSlideDown = isMobile && isCollapsible
    const areAllChildrenDisabled = !(isFinalSale && layout === "swatch")
      ? children.every((node) => node.props.disabled)
      : false
    const titleSupplementStyles =
      titleSupplement === "On Sale" ? styles.onSaleTitleSupplement : styles.titleSupplement

    return (
      <div
        className={classNames(
          "component",
          "toggle-group-component",
          styles.component,
          className,
          styles[layout]
        )}
      >
        {(title || titleSupplement) && showTitle && (
          <div
            className={classNames(
              styles.titleContainer,
              areAllChildrenDisabled && styles.disabledToggleGroup
            )}
            onClick={this.handleToggleClick}
          >
            <div>
              {title && (
                <span className={styles.title} role="heading" aria-level={2}>
                  {title}
                </span>
              )}
              {titleSupplement && (
                <span className={titleSupplementStyles}>
                  {titleSupplement}
                  {tooltipText && (
                    <Tooltip
                      isOpen={showTooltip}
                      placement="top"
                      onClose={this.handleTooltipToggle}
                      onKeyDown={this.handleTooltipKeyDown}
                      strategy="fixed"
                      onBlur={this.handleTooltipOnBlur}
                      target={
                        <button
                          aria-label="Open Tooltip for Icon Status information"
                          className={styles.tooltipButton}
                          onClick={this.handleTooltipToggle}
                          onKeyDown={this.handleTooltipKeyDown}
                          onBlur={this.handleTooltipOnBlur}
                        >
                          <InfoCircleIcon />
                        </button>
                      }
                    >
                      <span className={styles.tooltipText}>{tooltipText}</span>
                    </Tooltip>
                  )}
                </span>
              )}
              {showError && (
                <span className={styles.errorText}>{`Select ${title.toLowerCase()}`}</span>
              )}
            </div>
            {isCollapsible && (
              <button
                onClick={onCollapseClick}
                className={styles.chevronButton}
                aria-label={`${isCollapsed ? "Expand" : "Collapse"} ${name} section`}
              >
                <div className={classNames(styles.chevron, !isCollapsed && styles.chevronUp)}>
                  <ChevronIcons.Left />
                </div>
              </button>
            )}
          </div>
        )}

        {showSlideDown ? <SlideDown closed={isCollapsed}>{toggleList}</SlideDown> : toggleList}
      </div>
    )
  }
}

export default ToggleGroup
