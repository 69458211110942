/*
 * Restyle variant options to be smaller for this list of options. This performs a "fuzzy"
 * search, so the option must just contain the value. You can add to this list if additional options should be resized.
 */

const smallVariantOptions = RegExp(/(pant-?length|shirt-?neck|sleeve|size|waist)/)

export const optionSizeMapper = (option) => {
  return !!option && smallVariantOptions.test(option.toLowerCase()) ? "small" : "large"
}
