import { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import { Map } from "immutable"
import { camelize } from "humps"
import {
  getObjectByFirstField,
  getField,
  getImgixUrl,
  getImageAspectRatio,
} from "highline/utils/contentful/contentful_helper"
import { fitEducatorClosed } from "highline/redux/actions/product_detail_actions"
import { CloseIcon, PlayIcon } from "highline/components/icons"
import { setFixedDimensionsForUrl } from "highline/utils/url"
import NextImages from "highline/components/next_images"
import FitEducatorVideo from "highline/components/pdp/fit_educator_video"
import Modal from "highline/components/modal"

import styles from "highline/styles/components/pdp/fit_educator.module.css"

const FitEducator = memo(({ fitType, handleFitChange, isMobile }) => {
  const [showFitVideo, setShowFitVideo] = useState(false)
  const [showFitVideoModal, setShowFitVideoModal] = useState(false)
  const toggleFitVideoModalVisibility = () => setShowFitVideoModal(!showFitVideoModal)
  // Data from the Redux Store
  const fitEducatorDetails =
    useSelector((state) =>
      state.getIn(["sizeAndFit", "educationGroups", fitType, "educationItems"])
    ) || Map()

  const bundleSelectedFit = useSelector((state) => {
    const products = state.getIn(["bundleDetail", "products"])?.toJS()
    let selectedBundleFit

    if (products) {
      for (const product of products) {
        if (Boolean(product.selectedOptions?.[fitType])) {
          selectedBundleFit = product.selectedOptions[fitType]
          break
        }
      }
    }

    return selectedBundleFit ?? ""
  })

  const selectedFit =
    useSelector((state) => state.getIn(["productDetail", "selectedOptions", fitType])) ||
    bundleSelectedFit
  const contentfulData = useSelector((state) => state.getIn(["contentful", "globals"]))
  const productName = useSelector((state) => state.getIn(["productDetail", "name"]))
  const dispatch = useDispatch()
  const isFitVideoAbTestOn = useSelector((state) =>
    state.getIn(["sizeAndFit", "isFitVideoAbTestOn"])
  )
  const fitVideoAllowedProduct = useSelector((state) =>
    state.getIn(["sizeAndFit", "fitVideoWhitelistedProduct"])
  )

  useEffect(() => {
    const showFitVideo =
      isFitVideoAbTestOn && fitVideoAllowedProduct === productName && FitEducatorVideo !== undefined
    setShowFitVideo(showFitVideo)
  }, [isFitVideoAbTestOn])

  // Data from Contentful
  const contentfulFitData = getField(
    getObjectByFirstField(contentfulData, "Fit Educators"),
    "content"
  )
  const contentfulFitEducators = getField(
    getObjectByFirstField(contentfulFitData, fitType),
    "fitEducators"
  )
  const contentfulSelectedFitData = getObjectByFirstField(contentfulFitEducators, selectedFit)

  // Fit Image and Description setup
  const contentfulFitImage = getImgixUrl(getField(contentfulSelectedFitData, "fitImage"))
  const contentfulFitDescription = getField(contentfulSelectedFitData, "fitDescription")
  const fitImageAspectRatio = getImageAspectRatio(getField(contentfulSelectedFitData, "fitImage"))
  const fitImageWidth = 92
  const fitImageHeight = fitImageWidth / fitImageAspectRatio

  // Data from Highline
  const selectedFitDetails = fitEducatorDetails.find(
    (fit) => camelize(fit.get("title")) === camelize(selectedFit)
  )
  const fitMarkdown = selectedFitDetails ? selectedFitDetails.get("markdown") : null
  const fitIllustration = selectedFitDetails ? selectedFitDetails.get("imageUrl") : null

  const fitImage = contentfulFitImage || fitIllustration
  const fitDescription = contentfulFitDescription || fitMarkdown

  // If there is no fit image or description we don't want to render an empty box
  if (!fitDescription) {
    return null
  }

  return (
    <div
      id="fit-educator-component"
      className={classNames("component", "fit-educator-component", styles.component)}
    >
      {fitImage && (
        <div className={styles.imageWrapper}>
          {showFitVideo && (
            <button
              className={styles.fitEducatorVideoBtn}
              onClick={() => toggleFitVideoModalVisibility()}
              aria-label="open fit educator video modal"
            >
              <PlayIcon />
            </button>
          )}
          <NextImages
            src={setFixedDimensionsForUrl(fitImage, fitImageWidth, fitImageHeight)}
            alt={fitDescription}
            ariaLabel={fitDescription}
            wrapperClassNames={[styles.image]}
            width={fitImageWidth}
            height={fitImageHeight}
            unoptimized
          />
        </div>
      )}

      {showFitVideo && showFitVideoModal && (
        <Modal
          layout={isMobile ? "fullscreen" : "smallNoPadding"}
          onRequestClose={() => toggleFitVideoModalVisibility()}
          closeButtonLayout="noBackground"
          returnFocusRef={(ref) => (selectRef = ref)}
        >
          <FitEducatorVideo
            selectedFit={selectedFit}
            fitVideo={getField(contentfulSelectedFitData, "fitVideo")}
            productName={productName}
            muted={false}
          />
          <div className={classNames(styles.modalTextWrapper)}>
            <div className={classNames(styles.modalTextTitle)}>{selectedFit}</div>
            <div>{fitDescription}</div>
          </div>
        </Modal>
      )}

      <div className={classNames(styles.textWrapper, { [styles.noImage]: !fitImage })}>
        <div className={styles.textTitle}>{selectedFit}</div>
        <div className={styles.text}>{fitDescription}</div>
      </div>
      <button
        aria-label={"Close fit educator details"}
        className={styles.closeButtonCTA}
        onClick={() => {
          dispatch(fitEducatorClosed(productName))
          handleFitChange(false)
        }}
      >
        <CloseIcon />
      </button>
    </div>
  )
})

FitEducator.propTypes = {
  fitType: PropTypes.string.isRequired,
  handleFitChange: PropTypes.func,
  isMobile: PropTypes.bool,
}

FitEducator.defaultProps = {
  handleFitChange: () => {},
  isMobile: false,
}

FitEducator.displayName = "FitEducator"

export default FitEducator
