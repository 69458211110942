import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"
import {
  fitEducatorVideoPlayed,
  fitEducatorOptionClicked,
} from "highline/redux/actions/product_detail_actions"

const FitEducatorVideo = ({ fitVideo, selectedFit, productName, muted }) => {
  const [fitVideoComponent, setFitVideoComponent] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    const videoComponent = renderContentfulComponent(
      fitVideo,
      (url) => dispatch(fitEducatorVideoPlayed(url, productName, selectedFit)),
      `${productName}-${selectedFit}`,
      {
        onLoad: (url) => dispatch(fitEducatorOptionClicked(url, productName, selectedFit)),
        muted,
      }
    )

    setFitVideoComponent(videoComponent)
  }, [fitVideo, selectedFit])

  return <>{fitVideoComponent}</>
}

FitEducatorVideo.propTypes = {
  fitVideo: PropTypes.object.isRequired,
  muted: PropTypes.bool,
  productName: PropTypes.string,
  selectedFit: PropTypes.string,
}

FitEducatorVideo.defaultProps = {
  muted: false,
  productName: "",
  selectedFit: "",
}

export default FitEducatorVideo
